.App {
  background-color: black;
  color: white;
  height: auto;
  padding: 5rem;
  box-sizing: border-box;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  user-select: none;
}

input {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  height: 25px;
  font-size: 16px;
}

.ascii-art {
  font-family: monospace;
  white-space: pre;
}

.command {
  color: rgb(214, 237, 252);
}

.command:hover {
  cursor: pointer;
}

.account {
  color: #0f0;
  display: inline;
}

.terminal {
  display: inline;
}

.text-input {
  width: 80%;
}

.outputs {
  padding: 0;
  margin: 0;
  white-space: break-spaces
}

.spin {
  animation: spin 4s infinite linear;
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}